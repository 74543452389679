import React, { useEffect, useRef, useState } from "react";
import Navbar from "../Home/Navbar";
import Footer from "../Home/Footer";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";

import ProductCategory from "./ProductCategory";

// categories data
import { CategoriesData } from "./CategoriesData";

// images
import paypal from "../../assets/images/paypal.png";
import interac from "../../assets/images/interac.png";
import axios from "axios";
import Loader from "../Loader";
import PayPal from "../Account/PayPal";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";

const AddOrder = () => {
  const [showCart, setShowCart] = useState(false);

  const [cartItems, setCartItems] = useState([]);
  const [payMethod, setPayMethod] = useState("");

  const [showFixedCart, setShowFixedCart] = useState(false);

  const navigate = useNavigate();

  document.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      setShowFixedCart(true);
    } else {
      setShowFixedCart(false);
    }
  });

  useEffect(() => {
    if (
      !localStorage.getItem("vendor_id") &&
      !localStorage.getItem("auth_name")
    ) {
      navigate("/login");
    }
  }, []);

  useEffect(() => window.scrollTo(0, 0), []);

  const [isLoading, setLoading] = useState(false);
  const [categories, setCategory] = useState([]);
  const [items, setitems] = useState([]);

  useEffect(() => {
    fetch();
    fetch2();
    fetch3();
  }, []);

  const fetch = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: "https://api.rollzfranchise.com/api/item",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        const object = { qty: 0 };
        const isAdded = { isAdded: false };
        const activeData = response.data.data.filter(
          (items) => items.is_active == 1
        );
        const updatedArray = activeData.map((item) => ({
          ...item,
          ...object,
          ...isAdded,
        }));
        setitems(updatedArray);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const fetch2 = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: "https://api.rollzfranchise.com/api/item_category",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        setCategory(response.data.data);
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const [shipping_charges, setShippingCharge] = useState("");
  const [handling_charges, setHandling] = useState("");

  const fetch3 = async () => {
    setLoading(true);
    try {
      const response = await axios({
        method: "get",
        url: `https://api.rollzfranchise.com/api/customer/${localStorage.getItem(
          "vendor_id"
        )}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then((response) => {
        const data = response.data.data.customer.map((item) => {
          setHandling(item.handling_charge);
          setShippingCharge(item.shipping_charges);
        });
        setLoading(false);
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const [subtotal, setSubTotal] = useState("0");
  const [total_tax, setTotalTax] = useState("0");
  const [grand_total, setGrandTotal] = useState("0");
  const [paypal_charge, setPayPal_Charge] = useState("0");
  const [shipping, setShipping] = useState("0");

  useEffect(() => {
    let ColumnTotal = 0;
    let TotalTax = 0;
    let Total = 0;
    cartItems.forEach((item, i) => {
      const itemTotal = parseFloat(item.item_price) * parseFloat(item.qty);
      ColumnTotal += itemTotal;
      setSubTotal(ColumnTotal);

      const tax_total =
        parseFloat(item.item_price) *
        parseFloat(item.qty) *
        (parseFloat(item.percentage) / 100);
      TotalTax += tax_total;
      setTotalTax(TotalTax);

      const payCharge = ((ColumnTotal + TotalTax) * 3.98) / 100;
      setPayPal_Charge(payCharge);

      const Totals = itemTotal + tax_total;
      Total += Totals;

      if (payMethod == "paypal") {
        setGrandTotal(
          Total +
            parseFloat(shipping_charges) +
            parseFloat(handling_charges) +
            parseFloat(payCharge)
        );
      } else {
        setGrandTotal(
          Total + parseFloat(shipping_charges) + parseFloat(handling_charges)
        );
      }
    });
  }, [cartItems]);

  let GrandTotal = 0;
  let ColumnTotal = 0;
  let TotalTax = 0;
  let Total = 0;
  let PaypalCharges = 0;
  cartItems.forEach((item, i) => {
    const itemTotal = parseFloat(item.item_price) * parseFloat(item.qty);
    ColumnTotal += itemTotal;

    const tax_total =
      parseFloat(item.item_price) *
      parseFloat(item.qty) *
      (parseFloat(item.percentage) / 100);
    TotalTax += tax_total;

    const Totals = itemTotal + tax_total;
    Total += Totals;

    if (payMethod == "paypal") {
      if (shipping != 0) {
        PaypalCharges =
          ((ColumnTotal +
            TotalTax +
            parseFloat(shipping_charges) +
            parseFloat(handling_charges)) *
            3.98) /
          100;

        GrandTotal =
          Total +
          parseFloat(shipping_charges) +
          parseFloat(handling_charges) +
          parseFloat(PaypalCharges);
      } else {
        PaypalCharges =
          ((ColumnTotal + TotalTax + parseFloat(handling_charges)) * 3.98) /
          100;

        GrandTotal =
          Total + parseFloat(handling_charges) + parseFloat(PaypalCharges);
      }
    } else {
      if (shipping != 0) {
        GrandTotal =
          Total + parseFloat(shipping_charges) + parseFloat(handling_charges);
      } else {
        GrandTotal = Total + parseFloat(handling_charges);
      }
    }
  });

  // -------------------------------------------------------------
  // increase quantity
  const increaseQty = (itemsId, quantity) => {
    // updating category product quantity

    items.map((objs) => {
      if (objs.id == itemsId) {
        if (objs.qty < objs.quantity) {
          setitems((prevArray) => {
            return prevArray.map((obj) => {
              if (obj.id === itemsId && obj.qty < quantity) {
                return { ...obj, qty: obj.qty + 1 };
              } else {
                return obj;
              }
            });
          });
        } else {
          swal({
            text: "Order Quantity Exceeds Available Quantity!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
        }
      }
    });

    // updating cartItem quantity
    cartItems.map((objs) => {
      if (objs.id == itemsId) {
        if (objs.qty < objs.quantity) {
          setCartItems((prevArray) =>
            prevArray.map((obj) =>
              obj.id === itemsId ? { ...obj, qty: obj.qty + 1 } : obj
            )
          );
        } else {
          swal({
            text: "Order Quantity Exceeds Available Quantity!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          });
        }
      }
    });
  };

  const decreaseQty = (itemsId) => {
    // updating category product quantity
    setitems((prevArray) =>
      prevArray.map((obj) =>
        obj.id === itemsId && obj.qty > 1 ? { ...obj, qty: obj.qty - 1 } : obj
      )
    );

    // updating cartItem quantity
    setCartItems((prevArray) =>
      prevArray.map((obj) =>
        obj.id === itemsId && obj.qty > 1 ? { ...obj, qty: obj.qty - 1 } : obj
      )
    );
  };
  // ------------------------------------------------------------

  // cart to Cart
  const addToCart = (item, itemId) => {
    const newCartItem = {
      ...item,
      isAdded: true,
    };
    setCartItems([...cartItems, newCartItem]);

    setitems((prevArray) =>
      prevArray.map((obj) =>
        obj.id === itemId ? { ...obj, isAdded: true } : obj
      )
    );
  };

  // remove cart item
  const removeFromCart = (item, itemId) => {
    swal({
      title: "Are you sure?",
      text: "you want to remove this item",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setitems((prevArray) =>
          prevArray.map((obj) =>
            obj.id === itemId ? { ...obj, isAdded: false } : obj
          )
        );

        setCartItems(cartItems.filter((cartItem) => cartItem.id !== itemId));
      }
    });
  };
  // -------------------------------------------------------------

  const [checkout, setCheckout] = useState(false);
  const [show, setShow] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [file, setFile] = useState("");
  // place an order
  const onCreateOrder = (e) => {
    e.preventDefault();
    const item = cartItems.map((item) => {
      return item.id;
    });
    const Qty = cartItems.map((item) => {
      return item.qty;
    });
    const Price = cartItems.map((item) => {
      return item.item_price;
    });
    const TaxPercent = cartItems.map((item) => {
      return item.percentage;
    });

    const TaxTotal = cartItems.map((item, i) => {
      const SubTotal = parseFloat(item.item_price) * item.qty;
      const Percent = SubTotal * (parseFloat(item.percentage) / 100);
      return Percent;
    });

    e.preventDefault();
    if (visibility != true) {
      if (cartItems.length > 0) {
        swal({
          title: "Are you sure?",
          text: "you want to place the Order",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            setLoading(true);
            const formData = new FormData();
            formData.append("customer_id", localStorage.getItem("vendor_id"));
            formData.append("subtotal", subtotal);
            formData.append("tax", total_tax);
            formData.append("shipping", shipping);
            if (shipping == "0") {
              formData.append("shipping_amount", 0.0);
            } else {
              formData.append("shipping_amount", shipping_charges);
            }
            formData.append("discount", 0);
            formData.append("handling_charge", handling_charges);
            formData.append("total", GrandTotal);
            formData.append("payment_status", "unpaid");
            formData.append("order_status", "processing");
            formData.append("txn_type", payMethod);
            formData.append("id_item", item);
            formData.append("qty", Qty);
            formData.append("price", Price);
            formData.append("tax_amount", TaxTotal);
            formData.append("tax_percentage", TaxPercent);
            formData.append("interac_file", file);
            try {
              const response = await axios({
                method: "post",
                url: "https://api.rollzfranchise.com/api/create_customer_order",
                data: formData,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
              }).then((response) => {
                if (payMethod == "paypal") {
                  localStorage.setItem("order_id", response.data.data.order_id);
                  setCheckout(true);
                  setVisibility(true);
                  setLoading(false);
                } else {
                  swal({
                    text: "Order Placed Successfully",
                    icon: "success",
                    buttons: true,
                    dangerMode: true,
                  }).then(async (data) => {
                    navigate("/ordering");
                  });
                }
                setLoading(false);
              });
            } catch (err) {
              console.log(err);
              swal({
                icon: "error",
                text: err?.response?.data?.message,
              });
              setLoading(false);
            }
          }
        });
      } else {
        swal({
          title: "Cart is Empty!",
          text: "Please add some products into cart and then place the order",
          icon: "warning",
          dangerMode: true,
        }).then(() => {
          setShowCart(false);
        });
      }
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Navbar />
      <section className="pb-5 pt-4 mt-2">
        <div className="container-box">
          <div className="top-heading">
            <h1>Ordering</h1>
          </div>
          <div className="d-flex justify-content-end align-items-center mb-4">
            <div className="cart-btn d-flex" onClick={() => setShowCart(true)}>
              <i className="bi bi-cart4"></i>
              <span className="px-2">Cart</span>
              <span className="cartCountBtn shadow">{cartItems.length}</span>
            </div>
          </div>

          <div
            className={`cart-btn fixed-cart-btn text-center ${
              showFixedCart ? "d-flex" : "d-none"
            }`}
            onClick={() => setShowCart(true)}
          >
            <i className="bi bi-cart4"></i>
            <span className="cartCountBtn shadow">{cartItems.length}</span>
          </div>

          {categories.map((category, index) => {
            return (
              <div key={index} className="mb-4">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item bg-white">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          index === 0 ? "" : "collapsed"
                        } py-4`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#panelsStayOpen-collapseOne${index}`}
                        aria-expanded="true"
                        aria-controls={`panelsStayOpen-collapseOne${index}`}
                      >
                        <h5 className="mb-0 fw-bold text-pink">
                          {category.category_name}
                        </h5>
                      </button>
                    </h2>
                    <div
                      id={`panelsStayOpen-collapseOne${index}`}
                      className={`accordion-collapse collapse ${
                        index === 0 ? "show" : ""
                      }`}
                    >
                      <div className="accordion-body">
                        <div className="row pt-3">
                          <div className="col-12">
                            <div className="table-responsive">
                              <table className="table table-bordered text-center mobile-table">
                                <thead className="table-danger">
                                  <tr>
                                    <th className="text-start col-md-3">
                                      {" "}
                                      Item name
                                    </th>
                                    <th className="price-width">
                                      Price{" "}
                                      <span className="text-blue fs-6">
                                        (CAD)
                                      </span>
                                    </th>
                                    <th className="col-md-2">Quantity</th>
                                    <th className="col-md-1">SubTotal</th>
                                    <th className="tax-width">
                                      Tax{" "}
                                      <span className="text-blue ">(HST)</span>
                                    </th>
                                    <th className="col-md-1">Total</th>
                                    <th className="col-md-2">Action</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {items.map((product, i) => {
                                    if (category.id == product.id_category) {
                                      const SubTotal =
                                        parseFloat(product.item_price) *
                                        parseFloat(product.qty);

                                      const Percent =
                                        SubTotal *
                                        (parseFloat(product.percentage) / 100);

                                      const Total = SubTotal + Percent;

                                      // console.log(items)

                                      const updateArrayValue = (e, id) => {
                                        if (e.target.value > 0) {
                                          if (
                                            e.target.value <= product.quantity
                                          ) {
                                            setitems((prevItems) =>
                                              prevItems.map((item) =>
                                                item.id === id
                                                  ? {
                                                      ...item,
                                                      qty: e.target.value,
                                                    }
                                                  : item
                                              )
                                            );
                                            setCartItems((prevArray) =>
                                              prevArray.map((obj) =>
                                                obj.id === id && obj.qty
                                                  ? {
                                                      ...obj,
                                                      qty: e.target.value,
                                                    }
                                                  : obj
                                              )
                                            );
                                          } else {
                                            swal({
                                              text: "Order Quantity Exceeds Available Quantity!",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then((data) => {
                                              setitems((prevItems) =>
                                                prevItems.map((item) =>
                                                  item.id === id
                                                    ? {
                                                        ...item,
                                                        qty: product.quantity,
                                                      }
                                                    : item
                                                )
                                              );
                                              setCartItems((prevArray) =>
                                                prevArray.map((obj) =>
                                                  obj.id === id && obj.qty
                                                    ? {
                                                        ...obj,
                                                        qty: product.quantity,
                                                      }
                                                    : obj
                                                )
                                              );
                                            });
                                          }
                                        }
                                      };

                                      return (
                                        <tr key={i} className="bg-info">
                                          <td className="text-start">
                                            {product.item_name}
                                          </td>
                                          <td>${product.item_price}</td>
                                          <td>
                                            <button
                                              className="py-1 px-3 border-pink btn-light-pink"
                                              onClick={() =>
                                                decreaseQty(product.id)
                                              }
                                            >
                                              –
                                            </button>
                                            <input
                                              type="number"
                                              value={product.qty}
                                              className="py-1 border-pink text-center"
                                              style={{
                                                width: "46px",
                                                outline: "none",
                                              }}
                                              onChange={(e) =>
                                                updateArrayValue(e, product.id)
                                              }
                                            />
                                            <button
                                              className="py-1 px-3 border-pink btn-light-pink"
                                              onClick={() =>
                                                increaseQty(
                                                  product.id,
                                                  product.quantity
                                                )
                                              }
                                            >
                                              +
                                            </button>
                                          </td>
                                          <td>${SubTotal.toFixed(2)}</td>
                                          <td>
                                            ${Percent.toFixed(2)} (
                                            {product.percentage}%)
                                          </td>
                                          <td className="fw-bold">
                                            ${Total.toFixed(2)}
                                          </td>
                                          <td>
                                            {product.isAdded ? (
                                              <button
                                                className="add-to-cart bg-danger"
                                                onClick={() =>
                                                  removeFromCart(
                                                    product,
                                                    product.id
                                                  )
                                                }
                                              >
                                                {" "}
                                                <i className="bi bi-trash-fill"></i>{" "}
                                                Remove
                                              </button>
                                            ) : (
                                              <button
                                                className="add-to-cart"
                                                onClick={() => {
                                                  if (product.qty == 0) {
                                                    swal({
                                                      text: "Please Increase the order Quantity!",
                                                      icon: "warning",
                                                      buttons: true,
                                                      dangerMode: true,
                                                    });
                                                  } else {
                                                    addToCart(
                                                      product,
                                                      product.id
                                                    );
                                                  }
                                                }}
                                              >
                                                Add to Cart
                                              </button>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      <Footer />

      <Modal
        show={showCart}
        size="xl"
        centered
        onHide={() => setShowCart(false)}
        className={visibility == true ? "vis-hidden" : ""}
      >
        <Modal.Header className="bg-blue text-white">
          <Modal.Title className="fw-bold">Shopping Cart</Modal.Title>
          <div className="closeModalBtn" onClick={() => setShowCart(false)}>
            &times;
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={onCreateOrder}>
            <div className="table-responsive">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th>Sr No.</th>
                    <th>Product Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>SubTotal</th>
                    <th>Tax</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {cartItems.map((cartItem, i) => {
                    const SubTotal =
                      parseFloat(cartItem.item_price) *
                      parseFloat(cartItem.qty);

                    const Percent =
                      SubTotal * (parseFloat(cartItem.percentage) / 100);

                    const Total = SubTotal + Percent;

                    const updateArrayValue = (e, id) => {
                      if (e.target.value > 0) {
                        if (e.target.value <= cartItem.quantity) {
                          setitems((prevItems) =>
                            prevItems.map((item) =>
                              item.id === id
                                ? { ...item, qty: e.target.value }
                                : item
                            )
                          );
                          setCartItems((prevArray) =>
                            prevArray.map((obj) =>
                              obj.id === id && obj.qty
                                ? { ...obj, qty: e.target.value }
                                : obj
                            )
                          );
                        } else {
                          swal({
                            text: "Order Quantity Exceeds Available Quantity!",
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                          }).then((data) => {
                            setitems((prevItems) =>
                              prevItems.map((item) =>
                                item.id === id
                                  ? { ...item, qty: cartItem.quantity }
                                  : item
                              )
                            );
                            setCartItems((prevArray) =>
                              prevArray.map((obj) =>
                                obj.id === id && obj.qty
                                  ? { ...obj, qty: cartItem.quantity }
                                  : obj
                              )
                            );
                          });
                        }
                      }
                    };

                    return (
                      <tr key={i}>
                        <td>
                          <div className="py-2">{i + 1}</div>
                        </td>
                        <td>{cartItem.item_name} </td>
                        <td>${cartItem.item_price}</td>
                        <td>
                          <a
                            className="py-1 px-3 border-pink btn-light-pink"
                            onClick={() => decreaseQty(cartItem.id)}
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            –
                          </a>

                          <input
                            type="number"
                            value={cartItem.qty}
                            className="py-1 border-pink text-center"
                            style={{ width: "46px", outline: "none" }}
                            onChange={(e) => updateArrayValue(e, cartItem.id)}
                          />

                          <a
                            className="py-1 px-3 border-pink btn-light-pink"
                            onClick={() => increaseQty(cartItem.id)}
                            style={{
                              color: "black",
                              textDecoration: "none",
                              cursor: "pointer",
                            }}
                          >
                            +
                          </a>
                        </td>
                        <td>{SubTotal.toFixed(2)}</td>
                        <td>
                          ${Percent.toFixed(2)} ({cartItem.percentage}%)
                        </td>
                        <th>${Total.toFixed(2)}</th>
                        <td>
                          <i
                            className="bi bi-trash-fill fs-4 text-danger"
                            onClick={() =>
                              removeFromCart(cartItem, cartItem.id)
                            }
                            style={{ cursor: "pointer" }}
                            title="Remove This Item"
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <p className="text-center text-danger fw-bold">
                {cartItems.length > 0 ? null : " Cart is empty ! "}
              </p>
            </div>

            <div className="row mt-4 bg-light p-4">
              <div className="col-md-6 ps-md-0 border-end">
                <div className="white-box px-4 py-2 bg-light shadow-none">
                  <h2>Shipping method</h2>

                  <div className="row">
                    <div className="col-6 p-3">
                      <label htmlFor="0">
                        <div
                          className={`bg-white px-5 d-flex align-items-center pay-method
                          ${shipping === "0" && "selected-pay-method"}`}
                        >
                          <input
                            type="radio"
                            onChange={(e) => {
                              setShipping(e.target.id);
                            }}
                            id="0"
                            name="shipping_method"
                            style={{ marginRight: "15px" }}
                            required
                            defaultChecked={true}
                          />
                          Pickup
                        </div>
                      </label>
                    </div>
                    <div className="col-6 p-3">
                      <label htmlFor="1">
                        <div
                          className={`bg-white px-5 d-flex align-items-center pay-method
                          ${shipping === "1" && "selected-pay-method"}`}
                        >
                          <input
                            type="radio"
                            onChange={(e) => {
                              setShipping(e.target.id);
                            }}
                            id="1"
                            style={{ marginRight: "15px" }}
                            name="shipping_method"
                            required
                          />
                          Shipping
                        </div>
                      </label>
                    </div>
                  </div>

                  <h2 className="mt-2">Select Payment method</h2>
                  <div className="row">
                    <div className="col-6 mt-3">
                      <label htmlFor="paypal">
                        <div
                          className={`bg-white px-5 d-flex align-items-center pay-method
                          ${payMethod === "paypal" && "selected-pay-method"}`}
                          onClick={() => {
                            swal({
                              text: "For Paypal Method 3.98% charge will be added to your Grand Total, Are you sure You want to use Paypal !",
                              icon: "warning",
                              dangerMode: true,
                            }).then((willDelete) => {
                              setPayMethod("paypal");
                              setFile([]);
                            });
                          }}
                        >
                          <input
                            type="radio"
                            id="paypal"
                            name="payment-method"
                            required
                            // Checked={payMethod == "paypal"}
                            // disabled={payMethod != "paypal"}
                          />
                          <img src={paypal} alt="payment logo" />
                        </div>
                      </label>
                    </div>
                    <div className="col-6 mt-3">
                      <label htmlFor="interac">
                        <div
                          className={`bg-white px-5 d-flex align-items-center pay-method ${
                            payMethod === "interac" && "selected-pay-method"
                          }`}
                          onClick={() => {
                            swal({
                              text: "Please upload transaction receipt and etranster to  franchise@rollzicecream.ca",
                              icon: "warning",
                              dangerMode: true,
                            }).then((willDelete) => {
                              setPayMethod("interac");
                              setFile([]);
                            });
                          }}
                        >
                          <input
                            type="radio"
                            // onChange={(e) => setPayMethod(e.target.id)}
                            id="interac"
                            name="payment-method"
                            required
                          />
                          <img src={interac} alt="payment logo" />
                        </div>
                        {/* {
                          payMethod === "interac" ?
                            <span className="text-danger" style={{ fontSize: '13px' }}>*Please upload transaction receipt</span> : null
                        } */}
                      </label>
                    </div>
                    {payMethod === "interac" ? (
                      <div className="col-12 mt-4">
                        <label htmlFor="pick-up" className="ms-2">
                          Upload File
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          name="shipping_method"
                          value={file[0]}
                          onChange={(e) => setFile(e.target.files[0])}
                          required
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-md-6 pe-md-0">
                <div className="white-box px-4 py-2 bg-light shadow-none">
                  <h2>Cart total</h2>

                  <div className="mt-3">
                    <div className="d-flex justify-content-between py-1">
                      <span>Sub total (EXCL. TAX)</span>
                      <span>${parseFloat(subtotal).toFixed(2)}</span>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <span>Shipping charges</span>
                      <span>
                        $
                        {shipping != 0
                          ? parseFloat(shipping_charges).toFixed(2)
                          : (0.0).toFixed(2)}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <span>Handling charges</span>
                      <span>${handling_charges}</span>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <span>Tax (HST)</span>
                      <span>${parseFloat(total_tax).toFixed(2)}</span>
                    </div>
                    {payMethod == "paypal" ? (
                      <div className="d-flex justify-content-between py-1">
                        <span>Paypal Charge (3.98%)</span>
                        <span>${parseFloat(PaypalCharges).toFixed(2)}</span>
                      </div>
                    ) : null}

                    <div className="d-flex justify-content-between border-top text-pink mt-3 pt-3 mb-1 py-1">
                      <h4 className="fw-bold">Grand total =</h4>
                      <h4 className="fw-bold">
                        ${parseFloat(GrandTotal).toFixed(2)}
                      </h4>
                    </div>

                    <div className="my-2">
                      {checkout == true ? (
                        <PayPal
                          grand_total={GrandTotal}
                          subtotal={subtotal}
                          shipping={shipping}
                          shipping_charges={shipping_charges}
                          handling_charges={handling_charges}
                          total_tax={total_tax}
                          payMethod={payMethod}
                          PaypalCharges={PaypalCharges}
                        />
                      ) : (
                        <button className="button place-order-btn fw-bold w-100 btn-blue">
                          <i className="bi bi-check2-circle me-1 icon-center"></i>{" "}
                          PLACE AN ORDER
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddOrder;
